import React from "react"
import { Form } from "skillstrainer-resource-library"
import EventFormBuilder from "../formBuilder/event-form-builder"
import { navigate } from "gatsby"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"

export default function EventForm() {
  const handleSubmit = async formData => {
    const payload = {
      data: {
        entity: {
          type: "event-registration",
          eventId: 1,
        },
        operation: "insert",
        coupon_code: formData?.coupon_code,
        data: {
          full_name: formData.full_name,
          designation: formData?.designation,
          company_name: formData?.company_name,
          email: formData.email,
          phone_number: formData.phone_number,
          registration_type: formData.registration_type,
          number_of_employees: formData.number_of_employees,
        },
      },
    }
    try {
      const result = await axios.post(`${process.env.URL}/api/orders`, payload)

      console.log("result==", result.data.error)

      const paymentURL = new URL(`${process.env.ESG_HANDLER}/?payloadBase64=`)

      const payLOadJson = JSON.stringify(payload)
      const payloadBase64 = window.btoa(payLOadJson)

      paymentURL.searchParams.set("payloadBase64", payloadBase64)
      window.open(paymentURL.href, "_self")
    } catch (err) {
      const splitData = err?.response?.data?.error?.details.errors.map(data => {
        return data.message.split(" ")[0]
      })
      toast.error("Please Check" + " " + splitData.map(data => data), {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }
  return (
    <>
      <div className="mt-5 px-5">
        <ToastContainer />

        <h1 className="heading">
        CSC-IESGN SDG CONCLAVE - Terms and Conditions
        </h1>
        {/* <div className="comman-para">
          To secure your spot at the CSC-IESGN SDG CONCLAVE.
        </div>
        <div className="comman-para">
          <b>Option 1:</b> Make an advance payment of ₹5000. This amount will be
          adjusted in the final registration payment, and the remaining balance
          can be paid by January 25, 2024.
          <br />
          <b>Option 2:</b> Pay the full registration amount at once.
        </div> */}
        {/* <br /> */}
        <ul className="comman-para">
          <li>
            For individual bookings, please provide your Full Name, Email
            Address, and Contact Number. For corporate bookings, include
            Organisation Name, Contact Person's Full Name, Email Address, and
            Contact Number.
          </li>
          <li>
            Indian ESG Network Members can avail an additional 25% discount.
            Contact us on IESGN WhatsApp at +91 70420 03364 to obtain the
            discount code.
          </li>
          <li>
            Confirmation along with e-invoices/e-receipts will be sent within
            48-72 hours of the initial payment.
          </li>
          <li>
            For invoices in your organisation's name, please email
            contact@indianesgnetwork.com with billing details.
          </li>
          {/* <li>
            Conference delegate registration includes participation in site
            visits, Sessions, Site Visits and local transportation within sites.
          </li> */}
          <li>
            Please note that the event cost does not include lodging, travel
            costs and any other miscellaneous expenses.
          </li>
          <li>
            For further inquiries or assistance, feel free to reach out to us
            via email at contact@indianesgnetwork.com or contact our support on
            IESGN WhatsApp at +91 70420 03364.
          </li>
        </ul>
        <div className="comman-para fw-bold">
          Coupon code (Exclusively for IESGN Network Members)
        </div>

        <h1 className="heading my-5">REGISTER AND PAYMENT</h1>
        {/* <Form
          formBuilder={EventFormBuilder}
          className="ml-6 mt-2 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className: "btn learn-more-btn",
          }}
          onSubmit={handleSubmit}
        /> */}
      </div>
    </>
  )
}
