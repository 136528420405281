import React, { useEffect, useState } from "react"

function EventFormBuilder(values) {
  const [schema, setSchema] = useState({})

  useEffect(() => {
    const schema = {
      full_name: {
        label: "Full Name",
        type: "text",
        required: true,
      },
      email: {
        label: "Email",
        type: "email",
        required: true,
      },
      phone_number: {
        label: "Phone Number",
        type: "text",
        required: true,
      },
      designation: {
        label: "Designation",
        type: "text",
      },
      company_name: {
        label: "Organisation Name",
        type: "text",
      },
      coupon_code: {
        label: "Coupon Code",
        type: "text",
      },
      registration_type: {
        label: "Registration Type",
        type: "select",
        required: true,
        options: [
          {
            label: "Registration fee ₹3000 + 18% GST",
            value: "Advanced Payment",
          },
          // {
          //   label: "Corporate, Rs 20,000 + 18% GST",
          //   value: "Corporate",
          // },
          // {
          //   label: "Individual/Corporate Registration Cost: ₹20,000 + 18% GST",
          //   value: "Individual/Corporate",
          // },
        ],
      },
      // number_of_employees: {
      //   label: "No. of Employees",
      //   type: "hidden",
      // },
    }
    // if (values.registration_type === "Corporate") {
    //   schema.number_of_employees = {
    //     label: "No. of Employees",
    //     type: "number",
    //     required: true,
    //   }
    // }

    setSchema(schema)
  }, [values])
  return schema
}

export default EventFormBuilder
